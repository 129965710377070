import request from '@/utils/request'

export function queryOrderList (data) {
  return request({
    url: '/pay/transaction/orderList',
    data
  })
}

export function queryOrderListNew (params) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'get',
    url: '/admin/orders',
    params
  })
}

export function orderRefund (data) {
  return request({
    url: '/pay/order/refund',
    data
  })
}

export function testUserList (data) {
  return request({
    url: '/user/testUserList',
    data
  })
}

export function testUserAdd (data) {
  return request({
    url: '/user/testUserAdd',
    data
  })
}

export function testUserDel (data) {
  return request({
    url: '/user/testUserDel',
    data
  })
}
