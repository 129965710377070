<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-row>
          <el-col :span="3">
            <el-form-item>
              <el-input
                v-model="formData.orderId"
                clearable
                placeholder="订单编号/用户ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-input
                v-model="formData.thirdOrderId"
                clearable
                placeholder="第三方订单号"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-input
                v-model="formData.goodsName"
                clearable
                placeholder="商品名称"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-select
                v-model="formData.payType"
                placeholder="付款方式"
                multiple
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(item, index) in goodsChannelList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-select
                v-model="formData.chargeScene"
                placeholder="充值场景"
                multiple
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(item, index) in chargeSceneList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-select
                v-model="formData.appKey"
                placeholder="选择APP"
                multiple
                collapse-tags
                clearable
              >
                <el-option
                  v-for="(item, index) in appKeysList"
                  :key="index"
                  :label="item.name"
                  :value="item.appKey"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-input
                v-model="formData.version"
                width="80"
                clearable
                placeholder="版本号"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <!-- <el-select
              v-model="formData.orderStatus"
              placeholder="订单状态"
              multiple
              collapse-tags
              clearable
            >
              <el-option
                label="成功"
                :value="3"
              />
              <el-option
                label="退款"
                :value="7"
              />
            </el-select> -->
            <el-select
              v-model="formData.orderStatus"
              placeholder="订单状态"
              multiple
              collapse-tags
              clearable
            >
              <el-option
                v-for="(item, index) in ordersStatusList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="交易时间（UTC）">
            <el-date-picker
              v-model="datetimerange"
              type="datetimerange"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item label="消费金额">
            <el-input-number
              v-model="formData.minPrice"
              :precision="2"
              :step="0.01"
              controls-position="right"
              size="mini"
            />
            ~
            <el-input-number
              v-model="formData.maxPrice"
              :precision="2"
              :step="0.01"
              controls-position="right"
              size="mini"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formData.nationCode"
              placeholder="地区"
              filterable
              clearable
            >
              <el-option
                v-for="(item,index) in CountryAbbreviation"
                :key="index"
                :label="item.name + '('+ item.value +')'"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formData.testUserType"
              placeholder="测试用户"
              filterable
              clearable
            >
              <el-option
                label="全部"
                :value="0"
              />
              <el-option
                label="测试用户"
                :value="1"
              />
              <el-option
                label="非测试用户"
                :value="2"
              />
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <template v-slot:right>
        <div style="width:200px">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
          <el-button
            type="primary"
            @click="handleExport"
          >
            导出
          </el-button>
          <h3 style="padding-top:10px">
            总金额(USD) {{ orderListInfo.priceTotal }}
          </h3>
        </div>
      </template>
    </BHeader>
    <el-table
      v-loading="orderListInfo.loading"
      :data="orderListInfo.list"
      stripe
    >
      <el-table-column
        prop="user.nation_code"
        label="地区"
      />
      <el-table-column
        prop="user_id"
        label="用户ID"
        min-width="120"
      />
      <el-table-column
        prop="order_id"
        label="订单号"
        min-width="130"
      />
      <el-table-column
        prop="third_order_id"
        label="第三方订单号"
      />
      <el-table-column
        prop="goods.name"
        label="商品名称"
      />
      <el-table-column
        prop="goods.goods_id"
        label="商品ID"
      />
      <el-table-column
        prop="usd_price"
        label="付款金额(USD)"
      />
      <el-table-column
        prop="price"
        label="付款金额"
      />
      <el-table-column
        prop="currency"
        label="付款货币"
      />
      <el-table-column
        prop="pay_type"
        label="付款方式"
      >
        <template v-slot="{row}">
          <span>
            {{ goodsChannelMap[row.pay_type].name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderStatus"
        label="付款状态"
      >
        <template v-slot="{row}">
          <el-tag
            size="small"
          >
            {{ orderStatusList[row.order_status] }}
          </el-tag>
          <el-popover
            v-if="row.order_status == 3 && row.pay_type == 5"
            :ref="`popover-${row.order_id}`"
            placement="top"
            width="240"
            trigger="click"
          >
            <div style="margin: 5px;">
              <el-input
                v-model="formDataRefund.refundMoney"
                placeholder="请输入退款金额"
              >
                <el-button
                  slot="append"
                  @click="refundFun($event, row)"
                >
                  确认退款
                </el-button>
              </el-input>
            </div>
            <el-button
              slot="reference"
              size="small"
              type="danger"
              style="padding: 5px; margin-top: 10px"
              @click="showRefundBox(row)"
            >
              退款
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="charge_scene"
        label="充值场景"
      />
      <el-table-column
        prop="appkey"
        label="应用"
      >
        <template v-slot="{row}">
          {{ appKeysNameMap[row.appkey] || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="version"
        label="版本号"
      />
      <el-table-column
        prop="create_time"
        label="交易时间（UTC）"
        min-width="150"
      />
    </el-table>
    <Pagination
      :total="orderListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="queryOrderList()"
    />
  </Layout>
</template>

<script>
import {
  goodsTypeList,
  goodsTypesMap,
  chargeSceneList,
  chargeSceneMap,
  appIdMap,
  goodsChannelList,
  goodsChannelMap,
  ordersStatusList,
  CountryAbbreviation
} from '@/utils/selectOptions'
import { orderRefund, queryOrderListNew } from '@/api/transaction-management'
import { cloneDeep } from 'lodash'
import { batchQueryParamsForExport } from '@/utils'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { getAppKeys } from '@/api/blacklist.js'
import moment from 'moment'
export default {
  name: 'OrderList',
  data () {
    return {
      visible: false,
      goodsTypeList,
      goodsTypesMap,
      chargeSceneList,
      chargeSceneMap,
      appIdMap,
      goodsChannelList,
      goodsChannelMap,
      CountryAbbreviation,
      appKeysList: [],
      ordersStatusList,
      appKeysNameMap: [],
      formData: {
        orderId: '',
        testUserType: 2,
        thirdOrderId: '',
        goodsName: '',
        payType: [],
        chargeScene: [],
        appId: [],
        appKey: [],
        version: '',
        orderStatus: [3],
        minPrice: 0.0,
        maxPrice: 0.0,
        page: 1,
        pageSize: 20
      },
      datetimerange: this.getDateTimeRange(),
      orderListInfo: {
        loading: false,
        total: 0,
        priceTotal: 0,
        list: []
      },
      orderStatusList: { 1: '已下单', 3: '已支付', 7: '已退款' },
      formDataRefund: {
        orderId: 0,
        refundMoney: 0
      },
      batchUploadTemplate: {
        list: [],
        header: [
          '地区',
          '用户ID',
          '订单号',
          '第三方订单号',
          '商品名称',
          '商品ID',
          '付款金额(USD)',
          '付款金额',
          '付款货币',
          '付款方式',
          '付款状态',
          '充值场景',
          '应用',
          '版本号',
          '交易时间（UTC）'
        ],
        fields: [
          'nation_code',
          'user_id',
          'order_id',
          'third_order_id',
          'goods_name',
          'goods_id',
          'usd_price',
          'price',
          'currency',
          'pay_type',
          'order_status',
          'charge_scene',
          'appkey',
          'version',
          'create_time'
        ]
      }
    }
  },
  created () {
    this.queryOrderList()
    this.getAppKeys()
  },
  methods: {
    getDateTimeRange () {
      return [moment.utc().format('YYYY-MM-DD 00:00:00'), moment.utc().format('YYYY-MM-DD 23:59:59')]
    },
    handleSearch () {
      this.formData.page = 1
      this.queryOrderList()
    },
    queryOrderList () {
      this.orderListInfo.loading = true
      let startTime
      let endTime
      if (this.datetimerange && this.datetimerange.length) {
        startTime = this.datetimerange[0]
        endTime = this.datetimerange[1]
      }
      queryOrderListNew({
        ...this.formData,
        startTime,
        endTime
      })
        .then(res => {
          this.orderListInfo.list = res.data
          console.log(this.orderListInfo.list, 'this.orderListInfo.list')
          this.orderListInfo.total = res.total
          this.orderListInfo.priceTotal = res.price_total
        })
        .finally(() => {
          this.orderListInfo.loading = false
        })
    },
    getAppKeys () {
      getAppKeys({
        platform: ''
      }).then(res => {
        if (res.code === 200) {
          this.appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },
    showRefundBox (row) {
      this.formDataRefund.orderId = row.orderId
      this.formDataRefund.refundMoney = row.price
      this.stopScroll()
    },
    refundFun (index, row) {
      console.log(this.formDataRefund)
      orderRefund(this.formDataRefund).then(res => {
        if (res.code === 200) {
          if (res.data.success) {
            this.$message.success('退款成功！')
          } else {
            this.$message.error('退款失败！')
          }
          this.$refs[`popover-${row.orderId}`].doClose()
          this.queryOrderList()
        }
      })
      this.canScroll()
    },
    stopScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false)
    },
    canScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = ''
      document.removeEventListener('touchmove', mo, false)
    },
    handleExport () {
      this.listForExport()
        .then(data => {
          if (data) {
            this.formatToDownload(data)
            this.downloadExcel()
          }
        })
    },
    formatToDownload (data) {
      data.map(item => {
        item.nation_code = item?.user?.nation_code
        item.goods_name = item?.goods?.name
        item.goods_id = item?.goods?.goods_id
        item.pay_type = (goodsChannelMap[item.pay_type] || {}).name || item.pay_type
        item.order_status = this.orderStatusList[item.order_status]
        item.appkey = this.appKeysNameMap[item.appkey] || '未知'
        return item
      })
      this.batchUploadTemplate.list = data
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '交易订单' + '_' + new Date().toLocaleDateString())
    },
    listForExport () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.orderListInfo.loading = true
            const formData = cloneDeep(this.formData)
            const paramsList = batchQueryParamsForExport({
              params: {
                ...formData,
                pageSize: 100,
                startTime: this.datetimerange[0],
                endTime: this.datetimerange[1]
              },
              total: this.orderListInfo.total
            })
            Promise.all(paramsList.map(params => queryOrderListNew({ ...params, page: params.page })))
              .then(resultList => {
                const result = resultList.reduce((res, item) => {
                  return res.concat(item.data)
                }, [])
                resolve(result)
              })
              .finally(() => {
                this.orderListInfo.loading = false
              })
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
